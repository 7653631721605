import { useState, useEffect } from 'react';

export const mobilePortraitBreakpoint = 480;
export const tabletPortraitBreakpoint = 768;
export const mobileLandscapeBreakpoint = 896;
export const tabletLandscapeBreakpoint = 1024;
export const bigDesktopBreakpoint = 1800;


export enum DeviceMode {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
  BigDesktop = 'big desktop',
}

export enum Orientation {
  Portrait = 'portrait',
  Landscape = 'landscape',
}

function getViewportData() {
  const { innerWidth: width, innerHeight: height } = window;
  const ratio: number = width / height;
  const orientation: Orientation = ratio <= 1 ? Orientation.Portrait : Orientation.Landscape;
  
  // DIVICE MODE
  let mode: DeviceMode = DeviceMode.Desktop;
  if (width >= bigDesktopBreakpoint) {
    mode = DeviceMode.BigDesktop;
  }
  if (width <= tabletLandscapeBreakpoint) {
    mode = DeviceMode.Tablet;
  }
  if (width <= tabletPortraitBreakpoint) {
    mode = DeviceMode.Mobile;
  }
  if (width <= mobilePortraitBreakpoint) {
    mode = DeviceMode.Mobile;
  }

  // SCROLL
  const scrollTop =
    document.body.scrollTop || document.documentElement.scrollTop;
  const heightDelta =
    document.documentElement.scrollHeight -
    document.documentElement.clientHeight;
  const scrollPercentRaw = (scrollTop / heightDelta) * 100;
  const scrollPercent = ((isNaN(scrollPercentRaw) || scrollPercentRaw > 100) ? 100 : scrollPercentRaw).toFixed(2);

  return {
    width,
    height,
    orientation,
    mode,
    scrollTop,
    scrollPercent
  };
}

export interface ViewportData {
  diviceMode: DeviceMode;
  orientation: Orientation;
}

export default function useViewportData() {
  const [windowDimensions, setWindowDimensions] = useState(getViewportData());

  useEffect(() => {
    function handleScrollAndResize() {
      setWindowDimensions(getViewportData());
    }

    window.addEventListener('resize', handleScrollAndResize);
    window.addEventListener('scroll', handleScrollAndResize)
    return () => {
      window.removeEventListener('resize', handleScrollAndResize);
      window.removeEventListener('scroll', handleScrollAndResize);
    };
  }, []);

  return windowDimensions;
}