import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Spinner from "./shared/components/Spinner";
import useViewportData from "utilities/useViewport";
var classNames = require("classnames");
const Home = lazy(() => import("./pages/Home"));
const QuickStart = lazy(() => import("./pages/QuickStart"));
const OrderSuccess = lazy(() => import("./pages/OrderSuccess"));
const ErrorPage = lazy(() => import("./pages/Error/Error"));

export default function App() {
  const { mode, orientation } = useViewportData();
  return (
    <div className={classNames(mode, orientation)}>
      <Router>
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route exact path="/" component={Home}></Route>
            <Route
              exact
              path="/quickstart"
              component={() => {
                window.location.href = "https://youtu.be/9owNtmnHKEg";
                return null;
              }}
            ></Route>
            <Route
              exact
              path="/box-intro"
              component={() => {
                window.location.href = "https://youtu.be/9owNtmnHKEg";
                return null;
              }}
            ></Route>
            <Route exact path="/success" component={OrderSuccess}></Route>
            <Route exact path="*" component={ErrorPage}></Route>
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
}
